<template>
  <div>
    <Banner
      bannerTitle="金箱数云企业系统"
      bannerHref="https://admin.goldwms.cn"
      backImage="goldmatrix/banner2.png"
    ></Banner>
    <CommonTitle
      h-title="功能简介"
      h-descrip="Function introduction"
    ></CommonTitle>
    <div class="introduction">
      <FeatureContain
        v-for="item in introduct"
        :key="item.title"
        :imgUrl="item.imgUrl"
        :title="item.title"
        :info="item.info"
      ></FeatureContain>
    </div>
    <InfoTitle
      h-title="产业协同  数字创新"
      h-info="面向集团产业群、产业生态，提供数字协同及交易平台，提供技术服务"
    ></InfoTitle>
    <div class="feature">
      <div class="feature-box">
        <div class="top">
          <div class="top-left">
            <img src="../../assets/goldmatrix/compoterLeft.png" alt="" />
          </div>
          <div class="top-right">
            <companyBox
              class="com"
              :imgUrl="require(`@/assets/goldmatrix/estate-1.png`)"
              title="企业管理 | 仓储服务"
              content="为企业运营团队提供包括机构管理、员工管理、仓库数据构建、业务数据获取统计
                      精分、货品数据管理、流通数据获取整理统计等企业操作"
            ></companyBox>
            <companyBox
              :imgUrl="require(`@/assets/goldmatrix/estate-2.png`)"
              title="业务高效"
              content="帮助企业解决订单派单难、订单查找难、订单筛选难等多种订单烦恼；让企业提升效率，高效处理订单问题"
            ></companyBox>
          </div>
        </div>
        <div class="bottom">
          <div class="bottom-left">
            <companyBox
              class="com"
              :imgUrl="require(`@/assets/goldmatrix/estate-3.png`)"
              title="数据留存"
              content="企业主体、各个业务的操作人、平台流通的所有货品均数字化后完成验证，进行唯一编码、详细记录；
                      业务系统会记录每一个业务节点的所有交易数据，根据流程需求采集交易过程中的
                      溯源数据，包括时间、地点、人物、称重、拍照、员工认证、各个环节产生的票据
                      等，便于企业分析数据来源和原因，掌握业务信息，提升企业服务水平"
            ></companyBox
            ><companyBox
              :imgUrl="require(`@/assets/goldmatrix/estate-4.png`)"
              title="人员管理简单"
              content="企业添加员工采用业务授权的方式，所有员工均需要在平台完成实名认证，企业添加员工操作简单，效率高"
            ></companyBox>
          </div>
          <div class="bottom-right">
            <img src="../../assets/goldmatrix/computerRight.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="company">
      <CommonTitle
        h-title="企业入驻"
        h-descrip="Enterprise settlement"
      ></CommonTitle>
      <div class="company-box">
        <OrderContain :processList="processList"></OrderContain>
      </div>
    </div>
    <div class="apply">
      <CommonTitle
        h-title="手机应用"
        h-descrip="Mobile application"
      ></CommonTitle>
      <MediumContain
        :imgUrl="topBox.imgUrl"
        :h-title="topBox.title"
        :h-content="topBox.content"
        :icon="false"
        style="padding: 0 0 40px"
      ></MediumContain>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/banner";
import CommonTitle from "@/components/contain/commonTitle.vue";
import FeatureContain from "@/components/contain/featureContain.vue";
import InfoTitle from "@/components/contain/infoTitle.vue";
import Box from "@/components/box";
import OrderContain from "@/components/contain/orderContain.vue";
import MediumContain from "@/components/contain/mediumContain.vue";
import companyBox from "./companyBox";
export default {
  components: {
    Banner,
    CommonTitle,
    FeatureContain,
    InfoTitle,
    Box,
    OrderContain,
    MediumContain,
    companyBox,
  },
  data() {
    return {
      introduct: [
        {
          imgUrl: "goldmatrix/function-1.png",
          title: "组织架构",
          info: "企业组织架构管理、基础配置",
        },
        {
          imgUrl: "goldmatrix/function-2.png",
          title: "货品型号",
          info: "货品型号管理、型号审核",
        },
        {
          imgUrl: "goldmatrix/function-3.png",
          title: "货品管理",
          info: "货品数据构建、货品档案货品数据自定义",
        },
        {
          imgUrl: "goldmatrix/function-4.png",
          title: "业务管理",
          info: "业务单据管理、业务配置任务管理、三方业务",
        },
        {
          imgUrl: "goldmatrix/function-5.png",
          title: "金融&数据服务",
          info: "企业货押管理、解押申请货押开单、解押审核",
        },
      ],
      features: [
        {
          imgUrl: require("@/assets/goldmatrix/company-feature-1.png"),
          title: "企业管理 | 仓储服务",
          content: `为企业运营团队提供包括机构管理、员工管理、仓库数据构建、业务数据获取统计
精分、货品数据管理、流通数据获取整理统计等企业操作`,
        },
        {
          imgUrl: require("@/assets/goldmatrix/company-feature-2.png"),
          title: "业务高效",
          content: `帮助企业解决订单派单难、订单查找难、订单筛选难等多种订单烦恼；让企业提升
效率，高效处理订单问题`,
        },
        {
          imgUrl: require("@/assets/goldmatrix/company-feature-3.png"),
          title: "数据留存",
          content: `企业主体、各个业务的操作人、平台流通的所有货品均数字化后完成验证，进行唯
一编码、详细记录；
业务系统会记录每一个业务节点的所有交易数据，根据流程需求采集交易过程中的
溯源数据，包括时间、地点、人物、称重、拍照、员工认证、各个环节产生的票据
等，便于企业分析数据来源和原因，掌握业务信息，提升企业服务水平`,
        },
        {
          imgUrl: require("@/assets/goldmatrix/company-feature-4.png"),
          title: "人员管理简单",
          content: `企业添加员工采用业务授权的方式，所有员工均需要在平台完成实名认证，企业添
加员工操作简单，效率高`,
        },
      ],
      processList: [
        {
          imgUrl: "goldmatrix/process-1.png",
          title: "平台入驻",
        },
        {
          imgUrl: "goldmatrix/process-2.png",
          title: "创建基础组织架构",
        },
        {
          imgUrl: "goldmatrix/process-3.png",
          title: "添加员工、授权业务",
        },
        {
          imgUrl: "goldmatrix/process-4.png",
          title: "创建货品信息",
        },
      ],
      topBox: {
        imgUrl: "goldmatrix/phoneApp.png",
        title: "金箱数云",
        content: `APP实现了动态监控整个黄金供应链中货品的
                  流通，做到了智能、安全、高效；大大提高了业务
                  人员的工作效率，打破了传统的低效率管理模式，
                  提供数字化技术对工厂，门店，仓库的风险进行更
                  加准确的预警和处理；也进一步降低了企业经营者
                  对企业的管理成本！
                  `,
      },
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.introduction {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
}
.feature {
  width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
  background: url("../../assets/goldmatrix/feature-back.png") no-repeat
    center/cover;
  .feature-box {
    width: 1200px;
    height: 810px;

    .top {
      width: 1200px;
      height: 385px;
      display: flex;
      .top-left {
        width: 600px;
        height: 385px;
      }
      .top-right {
        width: 600px;
        height: 385px;
        padding: 40px 0 0 40px;
        box-sizing: border-box;
        .com {
          margin-bottom: 20px;
        }
      }
    }
    .bottom {
      width: 1200px;
      height: 385px;
      display: flex;
      .bottom-left {
        width: 600px;
        height: 385px;
        padding: 40px 0 0 40px;
        box-sizing: border-box;
        .com {
          margin-bottom: 20px;
        }
      }
      .bottom-right {
        width: 600px;
        height: 385px;
      }
    }
  }
}

.company {
  background: #f5f5f5;
  overflow: hidden;
  &-box {
    width: 1200px;
    margin: 0 auto;
  }
}
.apply {
}
</style>
