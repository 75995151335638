<template>
  <div>
    <div class="companyBox">
      <div class="title">
        <img :src="imgUrl" alt="" />
        <div>{{ title }}</div>
      </div>
      <div class="content">
        {{ content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "content", "imgUrl"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.companyBox {
  padding: 20px;
}
.title {
  height: 29px;
  color: #333333;

  line-height: 29px;
  display: flex;
  img {
    width: 32px;
    height: 29px;
    margin-right: 5px;
  }
  > div {
    font-size: 16px;
    font-weight: bold;
  }
}
.content {
  color: #666666;
  font-size: 14px;
  width: 504px;
  line-height: 24px;
  padding: 0 40px;
  box-sizing: border-box;
}
</style>
