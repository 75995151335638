<template>
  <div class="contain">
    <template v-for="(item, index) in processList">
      <div class="content" :key="item.title">
        <img :src="require('@/assets/' + item.imgUrl)" alt="" />
        <div class="title">{{ item.title }}</div>
      </div>
      <img
        class="arrow"
        v-if="!(index + 1 === processList.length)"
        :src="require('@/assets/goldmatrix/process-arrow.png')"
        :key="item.title"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    processList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.contain {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  box-sizing: border-box;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  img {
    width: 64px;
    height: 64px;
    margin-bottom: 30px;
  }
  .title {
    font-size: 16px;
    font-family: Noto Sans S Chinese-Regular, Noto Sans S Chinese;
    font-weight: 400;
    color: #333333;
  }
}
.arrow {
  width: 32px;
  height: 32px;
  margin: 0 40px;
}
</style>
